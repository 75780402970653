<template>
  <div class="login flex">
    <div class="login_left">
      <img src="../../assets/login.png" />
    </div>
    <div class="login_right">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <!-- <div class="title">{{ name }}</div> -->
        <el-form-item prop="username" label="用户名">
          <el-input
            :class="{ active: nameActive }"
            ref="name"
            v-model="loginForm.username"
            autofocus="autofocus"
            name="username"
            type="text"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
            @focus="(event) => inputFocus(event, 'name')"
            @blur="(event) => inputBlur(event, 'name')"
          />
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            :class="{ active: passwordActive }"
            v-model="loginForm.password"
            type="password"
            name="password"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
            @focus="(event) => inputFocus(event, 'password')"
            @blur="(event) => inputBlur(event, 'password')"
          />
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" class="submit-btn" @click.native.prevent="handleLogin">
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <img class="login_logo" src="../../assets/logo.png" />

    <div class="line-box">
      <span class="line-line"></span>
      <span class="line-text">广东顾诚智能科技有限公司·VR营销型网站模板管理平台</span>
      <span class="line-line"></span>
    </div>
  </div>
</template>

<script>
import service from "@/api/service.js"
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入账号"))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能小于6位"))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: "",
        password: "",
      },
      // 输入框校验
      loginRules: {
        username: [{ required: true, trigger: "blur", validator: validateUsername }],
        password: [{ required: true, trigger: "blur", validator: validatePass }],
      },
      loading: false,
      redirect: undefined,
      remember: false,
      name: "顾诚网站后台系统",
      nameActive: false,
      passwordActive: false,
    }
  },
  methods: {
    // 登录
    handleLogin() {
      const that = this
      that.$refs.loginForm.validate((valid) => {
        if (valid) {
          that.loading = true
          let condition = {
            username: that.loginForm.username,
            password: that.$md5(that.loginForm.password),
            role: "3",
          }
          service
            .login(condition)
            .then((res) => {
              if (res.data.code == 200 && res.data.data.flag) {
                // 保存登录信息
                localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo))
                // 保存token
                sessionStorage.setItem("authorization", res.data.token)
                that.$message({
                  type: "success",
                  message: "登录成功！",
                  duration: 1500,
                  onClose: function() {
                    that.$router.push("/admin")
                  },
                })
              } else {
                that.$message.error(res.data.err, 1)
              }
              that.loading = false
            })
            .catch((error) => {
              that.loading = false
              that.$message.error(error)
            })
        } else {
          return false
        }
      })
    },
    // 输入框获取焦点
    inputFocus(e, val) {
      if (val == "name") {
        this.nameActive = true
      } else if (val == "password") {
        this.passwordActive = true
      }
    },
    // 失去焦点
    inputBlur(e, val) {
      if (val == "name") {
        this.nameActive = false
      } else if (val == "password") {
        this.passwordActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$dark_gray: #ccc;
$light_gray: #333;
$login_theme: #00aaee;

/deep/ input {
  border: 0 none;
  background-color: white;
  -webkit-appearance: none;
  &:-webkit-autofill {
    background-image: none;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: $light_gray !important;
  }
}
/deep/ .el-input__inner {
  padding: 0 12px;
  background-color: white;
  border: 0 none;
  border-bottom: 1px solid #e6e6e6 !important;
}
/deep/ .el-form-item__error {
  left: 12px;
}

.active /deep/ .el-input__inner {
  border-bottom: 1px solid $login_theme !important;
}

.login {
  position: relative;
  height: 100vh;
  background-color: #f8f8f8;
  justify-content: center;
  align-items: center;

  &_left {
    position: relative;
    width: 600px;
    height: 600px;
    background-color: #fff;

    > img {
      position: absolute;
      width: 674px;
      height: 810px;
      z-index: 10;
      left: 0;
      top: -110px;
    }
  }

  &_right {
    position: relative;
    width: 600px;
    height: 600px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .el-form {
      width: 80%;
      margin: 0 auto;
      .title {
        font-size: 30px;
        color: $light_gray;
        margin: 0 auto 50px;
        text-align: center;
      }
      .submit-btn {
        width: 100%;
        line-height: 2;
        font-size: 16px;
        color: white;
        border-radius: 3px;
        background-color: $login_theme;
        display: block;
      }
      .el-button {
        border: 0 none;
      }
    }
  }

  &_logo {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 137px;
    height: 37px;
    z-index: 200;
  }

  /* 分割线 */
  .line-box {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
  }

  .line-line {
    flex-grow: 1;
    width: 40px;
    height: 1px;
    background-color: #ccc;
  }

  .line-text {
    margin: 0 10px;
    font-size: 0.28rem;
    color: #cccccc;
  }
}
</style>
